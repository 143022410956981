<template>
  <div style="text-align: center; margin-left: 20px">
    <!-- city image/singleFeature (verified) -->
    <div style="text-align: center;">
      <div class="shortlist-city-placeholder shortlisted-city elevation-2">
        <!-- show a loader to show the remove API is loading -->
        <v-progress-circular
          v-if="apiLoading"
          class="progress-loader"
          indeterminate
          color="attention"
        ></v-progress-circular>
        <!-- the background image of the city (cover) with gradient -->
        <v-img
          class="city-image"
          :src="getCityImage(city.img)"
          gradient="to top, rgba(0, 0, 0, .5) 5%, transparent"
        ></v-img>
        <!-- the shortlisted city remove button  -->
        <v-btn class="remove-city" icon color="error" @click="removeFn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- this partner badge icon only occurs for verified cities -->
        <!-- check for both isAdvance & isAdvanced (due to inconsistencies in the DB API param) -->
        <font-awesome-icon
          v-if="city.isAdvance || city.isAdvanced"
          class="city-partner-badge pa-1"
          :icon="['fas', 'handshake']"
          color="white"
          size="xs"
        />
        <!-- the name of the city -->
        <span
          class="
          city-title
          title
          text-left text-truncate
          platinum--text
          text-capitalize
        "
        >
        {{ city.city }}
      </span>
        <!-- the flag of the city's country -->
        <!--      <flag class="city-country-flag" :iso="city.countryFlag"/>-->
      </div>
    </div>
    <h2
      :title="capitalize(city.city)"
      class="
        headline
        text-left
        secondary--text
        text-capitalize text-truncate
        w-100
      "
    >
      {{ city.city }}
    </h2>
    <v-row class="d-flex flex-row flex-nowrap selected-venues-container mx-0 my-3" v-if="city && city.venue.length > 0">
      <v-col style="width: 200px" v-for="(venue, index) in city.venue" :key="index">
        <SingleVenues
          :cityId="city._id"
          :inCharge="isInCharge"
          :venue="venue"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// store
import {mapGetters} from "vuex";

// mixins
import ForNumber from "../../../../mixin/ForNumber";
import ForDecimal from "../../../../mixin/ForDecimal";
import SingleVenues from "./SingleVenuesCopy";
import rfpApicalls from "../../../../services/rfp.service";

export default {
  name: "SingleComparisonCity",
  props: ["city"],
  components: {
    SingleVenues
  },
  mixins: [ForNumber, ForDecimal],
  data() {
    return {
      apiLoading: false,
      isInCharge: false,
    };
  },
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP",
    }),
  },
  methods: {
    removeFn() {
      if (!this.$route.meta.isDisable) {
        if (!this.city.selected) {
          let payload = {
            id: this.city._id,
            rfpId: this.$route.params.id,
          };
          rfpApicalls.removeCity(payload).then((res) => {
            // console.log(res);
            let payload2 = {
              snackBarText: `The city, ${this.capitalize(
                this.city.city
              )}, is successfully removed from the RFP`,
              snackBarColor: "secondary",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.$store.dispatch("rfp/setSingleRFP", res.data);
          });
        } else {
          let payload2 = {
            snackBarText: `The city, ${this.capitalize(
              this.city.city
            )}, cannot be removed from the RFP (since one of its venues has been shortlisted)`,
            snackBarColor: "warning",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }
      }
    },
    getCityImage(arr) {
      // check if the object or the image array is empty (if yes, assign the placeholder image)
      if (this.city && this.city.img.length > 0) {
        let imgElement = null;
        // iterate the array and return the first instance of an image
        for (const element of arr) {
          if (this.isImage(element)) {
            imgElement = element;
            break;
          }
        }
        if (imgElement) {
          return imgElement;
        } else {
          return require("@/assets/placeholder.svg");
        }
      } else {
        return require("@/assets/placeholder.svg");
      }
    },
    // check if the url is linking to an image or video
    isImage(fileName) {
      if (fileName) {
        const extension = fileName.split(".").pop();
        if (extension == "mp4") return false;
        return true;
      }
      return false;
    },
    capitalize(name) {
      let res, firstChar, tail;
      name = name.trim().toLowerCase();
      res = name.split(" ");
      for (var i = 0; i < res.length; i++) {
        firstChar = res[i].substring(0, 1);
        firstChar = firstChar.toUpperCase();
        tail = tail = res[i].substring(1);
        res[i] = firstChar + tail;
        if (i == 0) {
          name = res[i];
        } else {
          name = name + " " + res[i];
        }
      }
      return name;
    },
  },
  watch: {
    singleRFP() {
      const payload = {
        cityId: this.city.cityId,
        rfpId: this.singleRFP._id
      }
      rfpApicalls.getIsCityInCharge(payload).then((res) => {
        this.isInCharge = res.data;
      });
    }
  }
};
</script>
<style scoped>
.progress-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.text-capitalize {
  text-transform: capitalize;
}

.city-feature-row {
  width: 100%;
  height: 36px;
  max-height: 36px;
}

.city-feature-row .feature-icon {
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.city-feature-row .feature-explainer-text {
  width: 100%;
  font-family: "TTHoves" !important;
}

.city-feature-row .feature-value-text {
  font-family: "TTHoves-Medium" !important;
}

.city-feature-row .feature-value-icon {
  width: 24px;
  height: 24px;
}

.city-feature-row .hr-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--v-platinum-base);
  margin: 0;
  padding: 0;
}

.shortlist-city-placeholder {
  height: 160px;
  width: 160px;
  border: 1px dotted var(--v-bedrock-base);
  border-radius: 5px;
  background-color: #ffffff;
}

.shortlist-city-placeholder.shortlisted-city {
  position: relative;
  border: none;
}

.shortlist-city-placeholder.shortlisted-city .city-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.shortlist-city-placeholder.shortlisted-city .city-title {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 70%;
  font-size: 1rem !important;
  line-height: 100%;
}

.shortlist-city-placeholder.shortlisted-city .city-partner-badge {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.shortlist-city-placeholder.shortlisted-city .city-country-flag {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.shortlist-city-placeholder.shortlisted-city .remove-city {
  position: absolute;
  top: 5px;
  right: -15px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

/* Large devices (large tablet to laptop) */
@media (min-width: 960px) and (max-width: 1264px) {
  .shortlist-city-placeholder {
    height: 120px;
    width: 120px;
  }
}

/* Medium devices (large tablet and below) */
@media (max-width: 960px) {
  .shortlist-city-placeholder {
    height: 90px;
    width: 90px;
  }

  .shortlist-city-placeholder.shortlisted-city .city-title {
    font-size: 0.75rem !important;
  }

  .shortlist-city-placeholder.shortlisted-city .city-partner-badge {
    width: 18px;
    height: 18px;
  }

  .shortlist-city-placeholder.shortlisted-city .city-country-flag {
    width: 18px;
    height: 18px;
  }
}
</style>
