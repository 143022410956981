<template>
  <v-col cols="12" class="px-0">
    <!-- individual row for event summary details -->
    <v-row class="px-0">
      <v-col cols="8">
        <span class="text-left subtitle-1">No. Delegates:</span>
      </v-col>
      <v-col cols="4">
        <span v-if="singleRFP.noOfDelegates" class="text-left subtitle-2">{{
          singleRFP.noOfDelegates
        }}</span>
        <span v-else class="text-left subtitle-2 platinum--text text--darken-3"
          >n/a</span
        >
      </v-col>
    </v-row>
    <!-- end of individual row for event summary details -->
    <!-- individual row for event summary details -->
    <v-row class="px-0">
      <v-col cols="8">
        <span class="text-left subtitle-1">No. Hotel Rooms:</span>
      </v-col>
      <v-col cols="4">
        <span v-if="singleRFP.noOfHotelRooms" class="text-left subtitle-2">{{
          singleRFP.noOfHotelRooms
        }}</span>
        <span v-else class="text-left subtitle-2 platinum--text text--darken-3"
          >n/a</span
        >
      </v-col>
    </v-row>
    <!-- end of individual row for event summary details -->
    <!-- individual row for event summary details -->
    <v-row class="px-0">
      <v-col cols="8">
        <span class="text-left subtitle-1">No. Breakout Rooms:</span>
      </v-col>
      <v-col cols="4">
        <span v-if="singleRFP.noOfBreakoutRooms" class="text-left subtitle-2">{{
          singleRFP.noOfBreakoutRooms
        }}</span>
        <span v-else class="text-left subtitle-2 platinum--text text--darken-3"
          >n/a</span
        >
      </v-col>
    </v-row>
    <!-- end of individual row for event summary details -->
    <!-- individual row for event summary details -->
    <v-row class="px-0">
      <v-col cols="8">
        <span class="text-left subtitle-1">Hybrid Solutions:</span>
      </v-col>
      <v-col cols="4">
        <template v-if="singleRFP.isHybridSolutions">
          <span
            class="text-left subtitle-2"
            v-for="(sol, index) in singleRFP.hybridSolutions"
            :key="index"
            >{{ sol }}</span
          >
        </template>
        <span v-else class="text-left subtitle-2 platinum--text text--darken-3"
          >No</span
        >
      </v-col>
    </v-row>
    <!-- end of individual row for event summary details -->
    <!-- individual row for event summary details -->
    <v-row class="px-0">
      <v-col cols="8">
        <span class="text-left subtitle-1">Start Date:</span>
      </v-col>
      <v-col cols="4">
        <template>
          <span
            class="text-left subtitle-2"
            >{{singleRFP.startDate !== null ? `${String(singleRFP.startDate.replace('T00:00:00.000Z', ''))}` : '-'}}</span
          >
        </template>
      </v-col>
    </v-row>
    <!-- end of individual row for event summary details -->
    <!-- individual row for event summary details -->
    <v-row class="px-0">
      <v-col cols="8">
        <span class="text-left subtitle-1">End Date:</span>
      </v-col>
      <v-col cols="4">
        <template>
          <span
            class="text-left subtitle-2"
            >{{singleRFP.endDate !== null ? `${String(singleRFP.endDate.replace('T00:00:00.000Z', ''))}` : '-'}}</span
          >
        </template>
      </v-col>
    </v-row>
    <!-- end of individual row for event summary details -->
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CitiesExclude",
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP"
    })
  }
};
</script>