<template>
  <v-col cols="12" class="d-flex flex-column align-start justify-center mt-12">
    <div :key="index" v-for="(options, index) in type !== 'Combined' ? list:combined_list"
         style="margin-bottom: 24px;width: 100%">
      <SingleCityFeatures :key="index" :options="options"/>
    </div>
  </v-col>
</template>

<script>
import SingleCityFeatures from "./SingleCityFeatures";

export default {
  name: "CityFeatures",
  props: ['type'],
  components: {
    SingleCityFeatures,
  },
  data() {
    return {
      list: [
        {
          name: "",
          icon: [],
          helperText: "",
        },
        {
          name: "Status",
          icon: ["fas", "building"],
          helperText: "",
        },
        {
          name: "Venue",
          icon: ["fas", "building"],
          helperText: "",
        },
        {
          name: "Address",
          icon: ["fas", "map-marker"],
          helperText: "",
        },
        {
          name: "No. of Meeting Rooms Offered",
          icon: ["fas", "users"],
          helperText: "",
        },
        {
          name: "No. of Hotel Rooms Offered",
          icon: ["fas", "bed"],
          helperText: "",
        },
        {
          name: "No. of Breakout Rooms Offered",
          icon: ["fas", "chalkboard"],
          helperText: "",
        },
        {
          name: "Sustainability Certificate",
          icon: ["fas", "leaf"],
          helperText: "",
        },
        {
          name: "Distance to Main Station",
          icon: ["fas", "train"],
          helperText: "",
        },
        {
          name: "Distance to City Centre",
          icon: ["fas", "city"],
          helperText: "",
        },
        {
          name: "Exhibition Space",
          icon: ["fas", "palette"],
          helperText: "",
        },
        {
          name: "Extra",
          icon: ["fas", "angle-down"],
          helperText: "",
          list: [
            {
              name: "Parking",
              icon: ["fas", "parking"],
              helperText: "",
            },
            {
              name: "Free Wi-Fi",
              icon: ["fas", "wifi"],
              helperText: "",
            },
            {
              name: "Accessible",
              icon: ["fas", "wheelchair"],
              helperText: "",
            },
            {
              name: "Gym",
              icon: ["fas", "dumbbell"],
              helperText: "",
            },
            {
              name: "Air-condition",
              icon: ["fas", "wind"],
              helperText: "",
            },
            {
              name: "Swimming Pool",
              icon: ["fas", "swimming-pool"],
              helperText: "",
            },
          ]
        },
        {
          name: "AV Equipment",
          icon: ["fas", "angle-down"],
          helperText: "",
          list: [
            {
              name: "Projector",
              icon: ["fas", "parking"],
              helperText: "",
            },
            {
              name: "Video Camera",
              icon: ["fas", "camera"],
              helperText: "",
            }
          ]
        },
        {
          name: "Catering",
          icon: ["fas", "angle-down"],
          helperText: "",
          list: [
            {
              name: "Breakfast",
              icon: ["fas", "utensil-spoon"],
              helperText: "",
            },
            {
              name: "Brunch",
              icon: ["fas", "wine-bottle"],
              helperText: "",
            },
            {
              name: "Lunch",
              icon: ["fas", "utensils"],
              helperText: "",
            }
          ]
        },
        {
          name: "AV Costs",
          icon: ["fas", "wrench"],
          helperText: "",
        },
        {
          name: "Catering Costs",
          icon: ["fas", "utensils"],
          helperText: "",
        },
        {
          name: "Room Rate(Single Use)",
          icon: ["fas", "door-closed"],
          helperText: "",
        },
        {
          name: "Hybrid Meeting PKG",
          icon: ["fas", "archway"],
          helperText: "",
        },
        {
          name: "DDR Rate",
          icon: ["fas", "signal"],
          helperText: "",
        },
        {
          name: "Total Offer",
          icon: ["fas", "s"],
          helperText: "",
        },
        {
          name: "Venue has selected (success fee)",
          icon: ["fas", "s"],
          helperText: "",
        }
      ],
      combined_list: [
        {
          name: "",
          icon: [],
          helperText: "",
        },
        {
          name: "Status",
          icon: ["fas", "building"],
          helperText: "",
        },
        {
          name: "Venue",
          icon: ["fas", "building"],
          helperText: "",
        },
        {
          name: "AV Costs",
          icon: ["fas", "wrench"],
          helperText: "",
        },
        {
          name: "Catering Costs",
          icon: ["fas", "utensils"],
          helperText: "",
        },
        {
          name: "Room Rate(Single Use)",
          icon: ["fas", "door-closed"],
          helperText: "",
        },
        {
          name: "Hybrid Meeting PKG",
          icon: ["fas", "archway"],
          helperText: "",
        },
        {
          name: "DDR Rate",
          icon: ["fas", "signal"],
          helperText: "",
        },
        {
          name: "Total Offer",
          icon: ["fas", "s"],
          helperText: "",
        },
        {
          name: "Venue has selected (success fee)",
          icon: ["fas", "s"],

        }
      ],
    };
  },

};
</script>

<style scoped>
.city-feature-row {
  width: 100%;
  height: 36px;
  max-height: 36px;
}

.city-feature-row .feature-icon {
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.city-feature-row .feature-explainer-text {
  width: 100%;
  font-family: "TTHoves" !important;
}

.city-feature-row .feature-value-text {
  font-family: "TTHoves-Medium" !important;
}

.city-feature-row .feature-value-icon {
  width: 24px;
  height: 24px;
}

.city-shortlist-container {
  position: relative;
  /* to offset the city details and image card */
  top: -220px;
  width: 100%;
  /* background-color: #777; */
  overflow-x: auto;
  /* firefox fix */
  scrollbar-width: thin;
}

.city-shortlist-container .shortlist-city-placeholder {
  height: 160px;
  width: 160px;
  border: 1px dotted var(--v-bedrock-base);
  border-radius: 5px;
  background-color: #ffffff;
}

.city-shortlist-container .shortlist-city-placeholder.shortlisted-city {
  position: relative;
  border: none;
}

.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-title {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 70%;
  font-size: 1rem !important;
  line-height: 100%;
}

.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-partner-badge {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-country-flag {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .remove-city {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

/* Large devices (large tablet to laptop) */
@media (min-width: 960px) and (max-width: 1264px) {
  .city-shortlist-container {
    /* reduce the offset to match the size changes */
    top: -180px;
  }

  .city-shortlist-container .shortlist-city-placeholder {
    height: 120px;
    width: 120px;
  }
}

/* Medium devices (large tablet and below) */
@media (max-width: 960px) {
  .city-shortlist-container {
    /* reduce the offset to match the size changes */
    top: -150px;
  }

  .city-shortlist-container .shortlist-city-placeholder {
    height: 90px;
    width: 90px;
  }

  .city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-title {
    font-size: 0.75rem !important;
  }

  .city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-partner-badge {
    width: 18px;
    height: 18px;
  }

  .city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-country-flag {
    width: 18px;
    height: 18px;
  }
}
.vertical-line {
  border-left: 1px solid #E8E9EB;
  height: 41%;
  position: absolute;
  /* top: 1350px; */
  bottom: 180px;
  left: 25%;
  transform: translateX(-50%);
}
</style>
