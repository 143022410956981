<template>
  <v-row class="d-flex flex-row align-start mb-1">
    <v-col cols="3" style="padding-top: 20px">
      <CityFeatures :type="singleRFP.rfptype"/>
    </v-col>
    <v-col cols="9" class="d-flex flex-column align-start justify-center mt-12" style="width: 55vw">
      <v-row
        class="d-flex flex-row flex-nowrap align-start justify-start mb-1 city-shortlist-container"
      >
        <SingleComparisonCity
          v-for="(city, index) in singleRFP.cityShortList"
          :key="index"
          :index="index"
          :city="city"
        />
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
import {mapGetters} from "vuex";
import CityFeatures from "./CityFeatures";
import SingleComparisonCity from "./SingleComparisonCity";

export default {
  name: "CompareCity",
  data: () => ({}),
  components: {
    CityFeatures,
    SingleComparisonCity,
  },
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP",
    })
  }
};
</script>
<style scoped>
/* breadcrumb status container design */
.breadcrumb-status-container {
  width: 100%;
  overflow: auto hidden;
  /* scrollbar size fix for firefox */
  scrollbar-width: thin;
}

.breadcrumb-status-container .breadcrumb-element {
  min-width: 410px;
}

.city-shortlist-container {
  position: relative;
  /* to offset the city details and image card */
  /* top: -220px; */
  margin-top: -220px;
  width: 100%;
  overflow-x: auto;
  /* firefox fix */
  scrollbar-width: thin;
}

/* Large devices (large tablet to laptop) */
@media (min-width: 960px) and (max-width: 1264px) {
  .city-shortlist-container {
    /* reduce the offset to match the size changes */
    /* top: -180px; */
    margin-top: -180px;
  }
}

/* Medium devices (large tablet and below) */
@media (max-width: 960px) {
  .city-shortlist-container {
    /* reduce the offset to match the size changes */
    /* top: -150px; */
    margin-top: -150px;
  }
}
</style>
