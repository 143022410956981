<template>
  <div class="d-flex align-center justify-start menu-stepper">
    <label class="tablinks" id="tab_1" :class="draft ? 'current' : null">
      <svg
        version="1.1"
        id="tab_icon_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 36"
        enable-background="new 0 0 200 36"
        xml:space="preserve"
      >
        <polygon class="st-0" points="180,0 0,0 0,36 180,36 200,18" />
      </svg>
      <!-- selected status details -->
      <v-row class="d-flex flex-row align-center justify-center status-details">
        <!-- if status is selected -->
        <font-awesome-icon
          :icon="['fas', 'file-alt']"
          :color="draft ? 'white' : 'primary'"
        />
        <span
          class="text-left px-2"
          :class="draft ? 'white--text' : 'primary--text'"
          >Draft</span
        >
      </v-row>
    </label>

    <label class="tablinks" id="tab_2" :class="open ? 'current' : null">
      <svg
        version="1.1"
        id="tab_icon_2"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 36"
        style="enable-background:new 0 0 200 36;"
        xml:space="preserve"
      >
        <polygon class="st-0" points="180,0 0,0 20,18 0,36 180,36 200,18" />
      </svg>
      <!-- unselected status details -->
      <v-row class="d-flex flex-row align-center justify-center status-details">
        <!-- if status is not selected -->
        <font-awesome-icon
          :icon="['fas', 'envelope-open-text']"
          :color="open ? 'white' : 'primary'"
        />
        <span
          class="text-left px-2"
          :class="open ? 'white--text' : 'primary--text'"
          >Open</span
        >
      </v-row>
    </label>

    <label class="tablinks" :class="pending ? 'current' : null" id="tab_3">
      <svg
        version="1.1"
        id="tab_icon_3"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 36"
        style="enable-background:new 0 0 200 36;"
        xml:space="preserve"
      >
        <polygon class="st-0" points="180,0 0,0 20,18 0,36 180,36 200,18" />
      </svg>
      <!-- unselected status details -->
      <v-row class="d-flex flex-row align-center justify-center status-details">
        <!-- if status is not selected -->
        <font-awesome-icon
          :icon="['fas', 'exclamation']"
          :color="pending ? 'white' : 'primary'"
        />
        <span
          :class="pending ? 'white--text' : 'primary--text'"
          class="text-left px-2"
          >Pending</span
        >
      </v-row>
    </label>

    <label class="tablinks" :class="completed ? 'current' : null" id="tab_4">
      <svg
        version="1.1"
        id="tab_icon_4"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 36"
        style="enable-background:new 0 0 200 36;"
        xml:space="preserve"
      >
        <polygon class="st-0" points="0,0 20,18 0,36 200,36 200,0" />
      </svg>
      <!-- unselected status details -->
      <v-row
        class="d-flex flex-row align-center justify-center status-details ml-2"
      >
        <!-- if status is not selected -->
        <font-awesome-icon
          :icon="['fas', 'clipboard-check']"
          :color="completed ? 'white' : 'primary'"
        />
        <span
          class="text-left px-2"
          :class="completed ? 'white--text' : 'primary--text'"
          >Completed</span
        >
      </v-row>
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP"
    }),
    draft() {
      if (
        this.singleRFP.status == "draft" ||
        this.singleRFP.status == "open" ||
        this.singleRFP.status == "pending" ||
        this.singleRFP.status == "completed"
      ) {
        return true;
      } else {
        return true;
      }
    },
    open() {
      if (
        this.singleRFP.status == "open" ||
        this.singleRFP.status == "pending" ||
        this.singleRFP.status == "completed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    pending() {
      if (
        this.singleRFP.status == "pending" ||
        this.singleRFP.status == "completed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    completed() {
      if (this.singleRFP.status == "completed") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
/* menu stepper CSS */
.menu-stepper {
  /* position: absolute; */
  position: relative;
  /* display: block; */
  height: 36px;
  /* width: 400px; */
  right: 0;
  left: 0;
  top: 0;
}
.st-0 {
  position: absolute;
  fill: var(--v-platinum-lighten5); /* white */
  stroke: var(--v-primary-base);
  stroke-miterlimit: 1;
}
.tablinks {
  /* display: block; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-width: 200px;
  height: 36px;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
}
.tablinks .status-details {
  position: absolute;
  z-index: 10;
  color: var(--v-primary-base);
  width: 100%;
}
.tablinks.current .status-details {
  /* white color */
  color: var(--v-platinum-lighten5);
}
.current .st-0 {
  fill: var(--v-primary-base);
  stroke: var(--v-platinum-lighten5);
}
.tab_buttons {
  display: none;
}
#tab_1 {
  left: -15px;
}
#tab_2 {
  left: -40px;
}
#tab_3 {
  left: -65px;
}
#tab_4 {
  left: -90px;
}
</style>