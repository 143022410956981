<template>
  <v-sheet
    :class="
      singleRFP.organiserProfile
        ? 'user-side-card'
        : 'user-side-card empty-card'
    "
    color="white"
    elevation="4"
  >
    <v-row
      class="
        d-flex
        flex-column flex-sm-row flex-md-column
        align-center
        justify-center
        px-0
        py-4
        pt-0
        mx-0
      "
    >
      <!-- user details -->
      <v-col
        cols="12"
        sm="6"
        md="12"
        class="d-flex flex-column align-center justify-center px-0 pt-0"
      >
        <!-- user organisation cover -->
        <v-row class="cover-img-row w-100">
          <v-img
            :src="
              singleRFP.organiserProfile &&
              singleRFP.organiserProfile.coverImg.length > 0
                ? singleRFP.organiserProfile.coverImg[0]
                : require(`@/assets/CoverPlaceholder.svg`)
            "
            alt="Cover Image"
            class="cover-img"
          ></v-img>
        </v-row>
        <!-- user avatar -->
        <v-row class="avatar-row elevation-1">
          <v-avatar class="d-none d-md-flex" size="150" color="white">
            <v-img
              :src="
                singleRFP.organiserProfile &&
                singleRFP.organiserProfile.logo.length > 0
                  ? singleRFP.organiserProfile.logo[0]
                  : require(`@/assets/UserPlaceholder.svg`)
              "
              alt="Logo"
            ></v-img>
          </v-avatar>
          <v-avatar class="d-flex d-md-none" size="100" color="primary">
            <v-img
              :src="
                singleRFP.organiserProfile &&
                singleRFP.organiserProfile.logo.length > 0
                  ? singleRFP.organiserProfile.logo[0]
                  : require(`@/assets/UserPlaceholder.svg`)
              "
              alt="Logo"
            ></v-img>
          </v-avatar>
        </v-row>
        <!-- {{singleRFP}} -->
        <!-- end of user avatar -->
        <!-- the user's name -->
        <h1 class="headline user-name text-center pt-3">
          {{ singleRFP.user.name }}
        </h1>
        <!-- the user details (additional) -->
        <v-col
          class="d-flex flex-column align-start justify-start mx-0 px-3 mb-3"
        >
          <!-- the user email -->
          <h5
            class="subtitle-2 text-left py-1 platinum--text text--darken-3"
            v-if="singleRFP.user.email"
          >
            Email:
            <a :href="`mailto:${singleRFP.user.email}`">{{
              singleRFP.user.email
            }}</a>
          </h5>
          <!-- the user mobile number -->
          <template v-if="singleRFP.organiserProfile">
            <h5
              class="subtitle-2 text-left py-1 platinum--text text--darken-3"
              v-if="
                singleRFP.organiserProfile.telephoneNo &&
                singleRFP.organiserProfile.telephoneNo.no
              "
            >
              Phone:
              <a
                :href="`tel:+${
                  singleRFP.organiserProfile.telephoneNo.code +
                  singleRFP.organiserProfile.telephoneNo.no
                }`"
                >{{
                  `+ ${singleRFP.organiserProfile.telephoneNo.code} ${singleRFP.organiserProfile.telephoneNo.no}`
                }}</a
              >
            </h5>
          </template>
        </v-col>
        <!-- the organisation name -->
        <h3 class="subtitle-1 organiation-name text-center">
          {{ singleRFP.user.organisation }}
        </h3>
        <v-col
          v-if="singleRFP.organiserProfile"
          class="d-flex flex-column align-start justify-start mx-0 px-3 mb-0"
        >
          <!-- the company type -->
          <h5
            class="subtitle-2 text-left py1 platinum--text text--darken-3"
            v-if="singleRFP.organiserProfile.organisationType"
          >
            Type:
            <span class="secondary--text">{{
              singleRFP.organiserProfile.organisationType
            }}</span>
          </h5>
          <!-- organisation website -->
          <h5
            class="subtitle-2 text-left py-1 platinum--text text--darken-3"
            v-if="singleRFP.organiserProfile.website"
          >
            Website:
            <a :href="`https://${singleRFP.organiserProfile.website}`">{{
              singleRFP.organiserProfile.website
            }}</a>
          </h5>
          <!-- organisation address -->
          <h5
            class="subtitle-2 text-left py-1 platinum--text text--darken-3"
            v-if="singleRFP.organiserProfile.address"
          >
            Address:
            <span class="secondary--text">{{ computedAddress }}</span>
          </h5>
        </v-col>
        <!-- member joined date (dd.mm.yy), only visible on sm -->
        <v-row
          class="d-none d-sm-flex d-md-none pt-2"
          align="center"
          justify="center"
        >
          <h6 class="caption text-center">
            Member Since: {{ computedDateFormat }}
          </h6>
        </v-row>
      </v-col>
      <!-- end of user details -->
      <v-col
        cols="12"
        sm="6"
        md="12"
        class="d-flex flex-column align-center justify-center py-1 mb-2"
      >
        <!-- social media link icons (will load dynamically based on all the registered SM links [Name, Icon, and URL]) -->
        <v-row
          v-if="singleRFP.organiserProfile"
          class="d-flex flex-row align-center justify-start flex-wrap"
        >
          <v-col
            cols="3"
            class="pa-1"
            v-for="socialMedia in computedSocialMedia"
            :key="socialMedia._id"
          >
            <template v-if="socialMedia.link">
              <v-btn
                class="d-flex align-center justify-center mx-1 social-icon"
                color="platinum"
                target="_blank"
                :href="socialMedia.link"
                icon
                tile
                small
              >
                <font-awesome-icon
                  :icon="['fab', socialMedia.icon]"
                  size="2x"
                  color="platinum"
                />
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <!-- end of social media links -->
      </v-col>
      <!-- member joined date (dd.mm.yy), hidden on  -->
      <v-col class="d-none d-md-flex align-center justify-center pt-2 w-100">
        <h6 class="caption text-center">
          Member Since: {{ computedDateFormat }}
        </h6>
      </v-col>
    </v-row>
    <v-btn
      class="user-profile-btn title ma-0 mt-3"
      color="secondary"
      height="50"
      @click="RouteFn"
      tile
      block
      bottom
      >View Profile</v-btn
    >
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP",
    }),
    computedSocialMedia() {
      let tmp = [];
      if (this.singleRFP.organiserProfile.socialMedia.length > 0) {
        tmp = this.singleRFP.organiserProfile.socialMedia.filter(
          (s) => s.link != ""
        );
      } else {
        return [];
      }
      return tmp;
    },
    computedDateFormat() {
      return this.singleRFP.user.created_at
        ? this.singleRFP.user.created_at.split("T")[0]
        : "";
    },
    computedAddress() {
      let address = this.singleRFP.organiserProfile.address;
      let str = "";
      if (address.street) {
        str = str + address.street + ",";
      }
      if (address.postalCode) {
        str = `${str} [${address.postalCode}], `;
      }

      if (address.city) {
        str = str + address.city + ",";
      }

      if (address.country) {
        str = str + address.country;
      }
      // {{`${singleRFP.organiserProfile.address.street} [${singleRFP.organiserProfile.address.postalCode}], ${singleRFP.organiserProfile.address.city}, ${singleRFP.organiserProfile.address.country
      return str;
    },
  },
  methods: {
    RouteFn() {
      //update Email in Vuex Store
      this.$store.dispatch("setUserEmail", this.singleRFP.user.email);
      localStorage.setItem("setUserEmail", this.singleRFP.user.email);
      //then do routing
      this.$router.push(`/organiser-view/${this.singleRFP.user._id}`);
      // console.log(id);
    },
  },
};
</script>
<style scoped>
.user-side-card {
  position: relative;
  width: 250px;
  min-height: 600px;
  overflow: hidden;
}
.user-side-card.empty-card {
  position: relative;
  width: 250px;
  min-height: 450px;
}
.user-side-card .user-name,
.user-side-card .organiation-name {
  text-transform: capitalize;
}
.user-side-card .cover-img-row {
  height: 125px;
  border-radius: 0;
  overflow: hidden;
}
.user-side-card .cover-img-row .cover-img {
  border-radius: 0;
}
.user-side-card .cover-img-row .cover-img >>> .v-image__image {
  border-radius: 0;
}
.user-side-card .avatar-row {
  border-radius: 50%;
  margin-top: -75px;
}
.user-side-card .social-icon {
  transition: all 100ms ease-in;
}
.user-side-card .social-icon:hover i {
  color: var(--v-secondary-base) !important;
}
.user-profile-btn {
  position: absolute;
  bottom: 0;
}
</style>