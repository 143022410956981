<template>
  <v-col cols="12" lg="12" class="ml-2 mr-6 pl-0" v-if="show">
    <v-row
          class="
            d-flex
            flex-row
            align-center
            justify-center
          "
        >
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <v-btn
    class="accept-venue"
    icon
    color="primary"
    @click="selectedFn()"
  >
    <font-awesome-icon
      class="feature-icon pa-1"
      :icon="['fas', 'trophy']"
      color="black"
      size="xs"
      v-if="venue.selected"
    />
    <v-icon size="32px" style="margin-left: 5px;" v-else>mdi-check</v-icon>
  </v-btn>
  <div style="text-align: center" v-if="!inCharge">
    <font-awesome-icon
      class="reject-venue feature-icon pa-1"
      style="top: 60px; right: 25px; cursor: pointer; background-color: #3C4858;"
      :icon="['fas', 'pen']"
      color="white"
      size="xs"
      @click="showEditProposal"
    />
  </div>
  <v-btn class="reject-venue" icon color="error" @click="removeFn" style="margin-left: 10px;">
    <v-icon size="32px" >mdi-close</v-icon>
  </v-btn>
</div>
</v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"
           style="margin-bottom: 15px;">
      <v-col
        cols="12"
        class="d-flex flex-column align-center justify-center py-1"
      >
        <h3 style="width: 100%;text-align: center;margin-bottom: 10px;margin-top: 10px;color: #F06543"
            v-if="venue.venueStatus !== undefined && venue.venueStatus !== ''">
          {{ venue.venueStatus === undefined || venue.venueStatus === '' ? 'No Status' : venue.venueStatus }}
        </h3>
        <h4 style="width: 100%;text-align: center;color: #969799"
            v-else>No Status</h4>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <template>
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1"  v-on="on">
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ venue.venueName }}
          </h4>
        <hr class="hr-divider w-100"/>
      </v-col>

      </template>
      <span>{{ venue.venueName }}</span>
    </v-tooltip>
        </template>

    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <template>
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1"  v-on="on">
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ computedAddress ? computedAddress : "N/A" }}
          </h4>
        <hr class="hr-divider w-100"/>
      </v-col>
      </template>
      <span>{{ computedAddress ? computedAddress : "N/A" }}</span>
    </v-tooltip>
        </template>
    </v-row>

    <!-- <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"
           v-if="venue.venueName !== 'combined'">
           <template>
          <v-tooltip right max-width="400">
            <template v-slot:activator="{ on }">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 platinum--text text--darken-2">
            {{ computedAddress ? computedAddress : "N/A" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </template>
    <span>{{ venue.venueName }}</span>
    </v-tooltip>
  </template>
    </v-row> -->
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ venue.noOfMeetRooms ? venue.noOfMeetRooms : "N/A" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ getRoomCount ? getRoomCount : "N/A" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            0
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ venue.isSustainabilityCertificate ? "Yes" : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ venue.distance2MainStation ? this.ForNumber(venue.distance2MainStation) + " km" : "N/A" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ venue.distance2CityCentre ? this.ForNumber(venue.distance2CityCentre) + " km" : "N/A" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ venue.exhibitSpace ? venue.exhibitSpace + 'm2' : "N/A" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>

    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 platinum--text text--darken-2">
            -
          </h4>
        </template>
      </v-col>
    </v-row>

    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'" style="margin-top: 20px">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ venue.isParkOnsite ? 'Yes' : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ venue.isFreeWifi ? 'Yes' : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ venue.isWheelchairAccesible ? 'Yes' : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ venue.isGym ? 'Yes' : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ venue.isAccomodationAircondition || venue.isFacilitiesAircondition ? 'Yes' : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            {{ venue.isInsideSwimmingpool || venue.isOutsideSwimmingpool ? 'Yes' : "No" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>

    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 platinum--text text--darken-2">
            -
          </h4>
        </template>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'" style="margin-top: 20px">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            No
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            No
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>

    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 platinum--text text--darken-2">
            -
          </h4>
        </template>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'" style="margin-top: 25px">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            No
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            No
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"  v-if="venue.venueName !== 'combined'">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: bold">
            No
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>

    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ aAVCosts ? "€" + forPrice(parseFloat(aAVCosts)) : "-" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ aCateringCosts ? "€" + forPrice(parseFloat(aCateringCosts)) : "-" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ aRoomRate ? "€" + forPrice(parseFloat(aRoomRate)) : "-" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ pkgPrice ? "€" + forPrice(parseFloat(pkgPrice)) : "-" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ ddrRate ? "€" + forPrice(parseFloat(ddrRate)) : "-" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text text-center text-bold text-truncate w-100 text--darken-2" style="font-weight: 900;font-size: 15px">
            {{ aTotalOffer ? "€" + forPrice(parseFloat(aTotalOffer)) : "-" }}
          </h4>
        </template>
        <hr class="hr-divider w-100"/>
      </v-col>
    </v-row>
    <v-row class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0">
      <v-col cols="12" class="d-flex flex-column align-center justify-center py-1">
        <template>
          <h4
            class="feature-value-text subtitle-2 text-center text-bold text-truncate w-100 platinum--text text--darken-2">
            {{ venue.commission ? venue.commission : "-" }}
          </h4>
        </template>
      </v-col>
    </v-row>
    <br/>
      <v-btn
        v-if="venue.aProposal === undefined"
        :disabled="true"
        :color="isDone ? 'primary' : 'secondary'"
        block
        tile
      >
        Submit Proposal
      </v-btn>
      <v-btn
        v-else
        :disabled="inCharge || venue.aProposal === undefined"
        :color="isDone ? 'primary' : 'secondary'"
        @click="adminAddFn('ADMIN')"
        block
        tile
      >{{ venue.aProposal === undefined ? '' : isDone ? "Submitted" : "Submit" }}
      </v-btn>

      <v-btn
        v-if="venue.proposalDoc === undefined || venue.proposalDoc.length<10"
        :disabled="inCharge"
        :color="venue.aProposal ? 'primary' : 'secondary'"
        @click="dialog = true"
        style="font-size: 11px;width: 103%"
        tile
        block
      >{{ venue.aProposal ? "Proposal Uploaded" : "Upload Proposal" }}
      </v-btn>
      <v-btn
        v-if="venue.proposalDoc && venue.proposalDoc.length>3"
        color="primary"
        tile
        block
        :href="venue.proposalDoc"
        target="_blank"
      >View Proposal
      </v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col
              cols="8"
              lg="10"
              class="d-flex flex-column justify-center align-start py-0"
            >
              <h2 class="headline text-left">Upload Proposal</h2>
            </v-col>
            <v-col
              cols="4"
              lg="2"
              class="d-flex flex-column justify-center align-end py-0"
            >
              <v-btn
                class="dialog-close-btn"
                icon
                color="grey lighten-1"
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <!-- this is the file input to upload the venue proposal -->
              <v-file-input
                label="Proposal"
                placeholder="Choose File"
                prepend-icon
                @change="fileChanged"
                append-icon="mdi-paperclip"
                clear-icon="mdi-close"
                truncate-length="14"
                clearable
                show-size
                outlined
                required
                v-model="uploadFile"
                hide-details="auto"
                :error-messages="errorFile"
              ></v-file-input>
              <v-row class="mx-3 mt-2 mb-6">
                  <span
                    class="
                      d-block
                      text-left
                      caption
                      platinum--text
                      text--darken-3
                      w-100
                    "
                  >Accepted formats: PDF, PNG, JPEG</span
                  >
                <span
                  class="
                      d-block
                      text-left
                      caption
                      platinum--text
                      text--darken-3
                      w-100
                    "
                >Maximum Size: 2 MB</span
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <!-- this is the file input to upload the venue proposal -->
              <v-file-input
                label="Supporting Documents"
                placeholder="Choose File"
                prepend-icon
                @change="fileChangedSupDoc"
                append-icon="mdi-paperclip"
                clear-icon="mdi-close"
                truncate-length="14"
                clearable
                show-size
                outlined
                required
                multiple
                v-model="uploadSupFile"
                hide-details="auto"
                :error-messages="errorFile"
              ></v-file-input>
              <v-row class="mx-3 mt-2 mb-6">
                  <span
                    class="
                      d-block
                      text-left
                      caption
                      platinum--text
                      text--darken-3
                      w-100
                    "
                  >Accepted formats: PDF, PNG, JPEG</span
                  >
                <span
                  class="
                      d-block
                      text-left
                      caption
                      platinum--text
                      text--darken-3
                      w-100
                    "
                >Maximum Size: 2 MB</span
                >
              </v-row>
            </v-col>
            <v-row>
              <v-col v-if="venue.aProposal">
                <span class="d-block text-left caption platinum--text text--darken-3 w-100" style="margin-bottom: 10px">
                  Available Proposals
                </span>
                <v-chip
                  class="proposal-link ma-0"
                  large
                  close
                  color="secondary"
                  label
                  text-color="white"
                  close-icon="mdi-download"
                  :href="venue.aProposal"
                  target="_blank"
                  style="height:30px;"
                >
                  <v-icon left>mdi-file</v-icon>
                  <span class="text-truncate">Uploaded Proposal</span>
                </v-chip>
                <v-divider style="margin-top: 5px;width: 100%"/>
              </v-col>
            </v-row>
            <v-row v-if="supDocs.length>0" style="padding-left: 25px">
              <v-col cols="12" md="12" lg="12">
                    <span class="d-block
                      text-left
                      caption
                      platinum--text
                      text--darken-3
                      w-100">Supporting Documents (To be uploaded)</span>
              </v-col>
              <v-col cols="12" md="4" lg="4" v-for="doc in supDocs" :key="doc"
                     class="d-flex flex-column align-sm-start px-0" style="margin-right: 2px;">
                <v-chip
                  class="proposal-link"
                  large
                  close
                  :color="uploadingSupDoc === doc?'success':'error'"
                  label
                  text-color="white"
                  close-icon="mdi-close"
                  target="_blank"
                  style="height:30px;"
                  @click:close="removePendingSupDoc(doc)"
                >
                  <v-icon left>mdi-file</v-icon>
                  <span class="text-truncate">{{ doc[0].name }}</span>
                </v-chip>
              </v-col>
            </v-row>
            <v-divider style="margin-top: 5px;width: 100%"/>
            <v-row style="padding-left:25px;" v-if="venue.supportDocs">
              <v-col cols="12" md="12" lg="12">
                <span class="d-block
                      text-left
                      caption
                      platinum--text
                      text--darken-3
                      w-100">Available Supporting Documents</span>
              </v-col>
              <v-col cols="12" md="3" lg="3" v-for="doc in venue.supportDocs" :key="doc"
                     class="d-flex flex-column align-sm-start px-0" style="margin-right: 2px">
                <v-chip
                  class="proposal-link ma-0"
                  large
                  close
                  color="secondary"
                  label
                  text-color="white"
                  close-icon="mdi-close"
                  :href="doc"
                  @click:close="removeSupportDoc(doc)"
                  target="_blank"
                  style="height:30px;"
                >
                  <v-icon left>mdi-file</v-icon>
                  <span class="text-truncate">{{ doc.split('/')[doc.split('/').length - 1] }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-row
            class="
                d-flex
                flex-column-reverse flex-sm-row
                align-center
                justify-space-between
                mx-0
              "
          >
            <v-col
              cols="12"
              sm="3"
              class="d-flex flex-column align-center align-sm-start px-0"
            >
              <v-btn
                class="px-8"
                color="secondary"
                @click="dialog = false"
                tile
                outlined
                depressed
                bottom
              >Cancel
              </v-btn
              >
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="d-flex flex-column align-center align-sm-end px-0"
            >
              <v-btn
                class="px-8 mx-0"
                @click="uploadProposal"
                :loading="isApicall"
                color="secondary"
                tile
                depressed
                bottom
                right
              >Submit
              </v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="600px">
      <v-card height="90vh" style="overflow-y: scroll">
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col
              cols="8"
              lg="10"
              class="d-flex flex-column justify-center align-start py-0"
            >
              <h2 class="headline text-left">Edit Proposal</h2>
            </v-col>
            <v-col
              cols="4"
              lg="2"
              class="d-flex flex-column justify-center align-end py-0"
            >
              <v-btn
                class="dialog-close-btn"
                icon
                color="grey lighten-1"
                @click="dialog2 = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field placeholder="No. of Meeting Rooms" @keypress="[isNumber()]" outlined dense
                            v-model="venue.noOfMeetRooms" label="No. of Meeting Rooms"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field placeholder="No. of Hotel Rooms" @keypress="[isNumber()]" outlined dense
                            v-model="getRoomCount" label="No. of Hotel Rooms"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field placeholder="No. of Breakout Rooms" @keypress="[isNumber()]" outlined dense
                            v-model="venue.noOfBreakoutRooms" label="No. of Breakout Rooms"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="Km" placeholder="Dist. to Main Station" @keypress="[isNumber()]" outlined dense
                            v-model="venue.distance2MainStation" label="Dist. to Main Station"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="Km" placeholder="Dist. to City Center" @keypress="[isNumber()]" outlined dense
                            v-model="venue.distance2CityCentre" label="Dist. to City Center"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="m2" placeholder="Exhibition Space" @keypress="[isNumber()]" outlined dense
                            v-model="venue.exhibitSpace" label="Exhibition Space"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Sustainability Certificate?
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isSustainabilityCertificate"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Free Wi-Fi
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isFreeWifi"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Wheelchair Accessible?
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isWheelchairAccesible"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Gym
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isGym"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Parking Onsite?
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isParkOnsite"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Price" outlined dense v-model="venue.parkOnsitePrice"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Air Conditioning
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isAirConditioning"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Swimming Pool
                  </h4>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch style="margin-top: -2px" v-model="venue.isParkOnsite"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="1" class="d-flex flex-column align-center justify-center px-0 py-1">
              <!-- the proposals row icon, the icon class can be populated from array -->
              <font-awesome-icon
                class="feature-icon pa-1"
                style="top: 40px;right:25px;cursor: pointer;background-color: #3c4858;"
                :icon="['fas', 'video']"
                color="white"
                size="xs"
              />
            </v-col>
            <!-- the proposals row explainer text -->
            <v-col
              cols="11"
              class=" d-flex flex-column align-start justify-center pl-2 py-1">
              <h5 class="proposals-explainer-text title-2 text-left text-truncate">
                AV Equipments
              </h5>
            </v-col>
            <v-col cols="12" md="2"></v-col>
            <v-col cols="12" md="8">
              <!--                <v-row-->
              <!--                  class="-->
              <!--                  venue-feature-row-->
              <!--                  flex-grow-0-->
              <!--                  d-flex-->
              <!--                  flex-row flex-nowrap-->
              <!--                  align-center-->
              <!--                  justify-between-->
              <!--                  mx-0-->
              <!--                  py-0-->
              <!--                "-->
              <!--                  v-for="(featuresList, index) in computedVenueAVList"-->
              <!--                  :key="index"-->
              <!--                >-->
              <!--                  &lt;!&ndash; the icon of the feature row  &ndash;&gt;-->
              <!--                  <v-col-->
              <!--                    cols="1"-->
              <!--                    class="-->
              <!--                    d-flex-->
              <!--                    flex-column-->
              <!--                    align-start-->
              <!--                    justify-center-->
              <!--                    px-0-->
              <!--                    py-1-->
              <!--                  "-->
              <!--                  >-->
              <!--                    &lt;!&ndash; the feature row icon, the icon class can be populated from array &ndash;&gt;-->
              <!--                    <font-awesome-icon-->
              <!--                      class="feature-icon pa-1"-->
              <!--                      :icon="['fas', featuresList.icon]"-->
              <!--                      color="white"-->
              <!--                      size="xs"-->
              <!--                    />-->
              <!--                  </v-col>-->
              <!--                  &lt;!&ndash; the feature row explainer text &ndash;&gt;-->
              <!--                  <v-col-->
              <!--                    cols="7"-->
              <!--                    class="-->
              <!--                    d-flex-->
              <!--                    flex-column-->
              <!--                    align-start-->
              <!--                    justify-center-->
              <!--                    pl-2-->
              <!--                    py-1-->
              <!--                  "-->
              <!--                  >-->
              <!--                    <h5-->
              <!--                      :title="featuresList.name"-->
              <!--                      class="-->
              <!--                      feature-explainer-text-->
              <!--                      subtitle-2-->
              <!--                      text-left text-truncate-->
              <!--                    "-->
              <!--                    >-->
              <!--                      {{ featuresList.name }}-->
              <!--                    </h5>-->
              <!--                  </v-col>-->
              <!--                  &lt;!&ndash; the feature row value text &ndash;&gt;-->
              <!--                  <v-col-->
              <!--                    cols="4"-->
              <!--                    class="d-flex flex-column align-end justify-center py-1 pr-0"-->
              <!--                  >-->
              <!--                    <h4-->
              <!--                      :title="featuresList.val"-->
              <!--                      class="-->
              <!--                      feature-value-text-->
              <!--                      subtitle-1-->
              <!--                      text-right text-bold text-nowrap-->
              <!--                    "-->
              <!--                    >-->
              <!--                      <b>{{ featuresList.val }}</b>-->
              <!--                    </h4>-->
              <!--                  </v-col>-->
              <!--                </v-row>-->
            </v-col>
            <v-col cols="12" md="2"></v-col>
            <v-col cols="12" class="py-0">
              <v-combobox
                label="AV Equipments"
                :items="venue.AVequipments"
                outlined
                multiple
                allow-overflow
                clearable
                deletable-chips
                chips
                v-model="venue.aAvEquipemnts"
              ></v-combobox>
            </v-col>
            <v-col
              cols="1" class="d-flex flex-column align-center justify-center px-0 py-1">
              <!-- the proposals row icon, the icon class can be populated from array -->
              <font-awesome-icon
                class="feature-icon pa-1"
                style="top: 40px;right:25px;cursor: pointer;background-color: #3c4858;"
                :icon="['fas', 'utensils']"
                color="white"
                size="xs"
              />
            </v-col>
            <!-- the proposals row explainer text -->
            <v-col
              cols="11"
              class=" d-flex flex-column align-start justify-center pl-2 py-1">
              <h5 class="proposals-explainer-text title-2 text-left text-truncate">
                Catering
              </h5>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-combobox
                label="Catering"
                :items="venue.aCatering"
                outlined
                multiple
                allow-overflow
                clearable
                deletable-chips
                chips
                v-model="venue.aCatering"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="AV Cost" outlined dense v-model="venue.aAVCosts" label="AV Cost"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Catering Cost" outlined dense v-model="venue.aCateringCosts"
                            label="Catering Cost"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Room Rate" outlined dense v-model="venue.aRoomRate"
                            label="Room Rate"/>
              <v-col cols="12" md="12" style="margin-top: -35px;margin-bottom: -40px">
                <v-row>
                  <v-col cols="12" md="9">
                    <h6>
                      Include Breakfast
                    </h6>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-switch style="margin-top: -2px" v-model="venue.breakfastInRoomRate"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Hybrid Marketing PKG" outlined dense v-model="venue.pkgPrice"
                            label="Hybrid Marketing PKG"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="DDR Rate" outlined dense v-model="venue.ddrRate"
                            label="DDR Rate"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Half Day DDR Rate" outlined dense v-model="venue.aHalfDayDDR"
                            label="Half Day DDR Rate"/>
              <v-col cols="12" md="12" style="margin-top: -35px;margin-bottom: -40px">
                <v-row>
                  <v-col cols="12" md="9">
                    <h6>
                      Inclusive Lunch
                    </h6>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-switch style="margin-top: -2px" v-model="venue.lunchInHalfDayDDR"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Room Rental Per Day" outlined dense v-model="venue.aRoomRentalDay"
                            label="Room Rental Per Day"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Room Rental Per Half Day" outlined dense
                            v-model="venue.aRoomRentalHalfDay"
                            label="Room Rental Per Half Day"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Total Amount Of Delegates" outlined dense
                            v-model="venue.aDelegateTotal"
                            label="Total Amount Of Delegates"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field suffix="€" placeholder="Total Offer" outlined dense v-model="venue.aTotalOffer"
                            label="Total Offer"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-row
            class="
                d-flex
                flex-column-reverse flex-sm-row
                align-center
                justify-space-between
                mx-0
              "
          >
            <v-col
              cols="12"
              sm="3"
              class="d-flex flex-column align-center align-sm-start px-0"
            >
              <v-btn
                class="px-8"
                color="secondary"
                @click="dialog2 = false"
                tile
                outlined
                depressed
                bottom
              >Cancel
              </v-btn
              >
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="d-flex flex-column align-center align-sm-end px-0"
            >
              <v-btn
                class="px-8 mx-0"
                @click="adminAddFn('');dialog2 = false"
                :loading="isApicall"
                color="secondary"
                tile
                depressed
                bottom
                right
              >Submit
              </v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
/* eslint-disable */
// import axios from "axios";
import rfpApicalls from "../../../../services/rfp.service";

import isNumber from "../../../../mixin/IsNumber";

import ForNumber from "../../../../mixin/ForNumber";
import ForDecimal from "../../../../mixin/ForDecimal";

export default {
  props: ["venue", "cityId", "inCharge"],
  mixins: [ForNumber, ForDecimal, isNumber],
  data() {
    return {
      show: false,
      file: false,
      dialog: false,
      dialog2: false,
      aAvEquipemnts: [],
      aCatering: [],
      aAVCosts: null,
      ddrRate: null,
      pkgPrice: null,
      aCateringCosts: null,
      aRoomRate: null,
      aHalfDayDDR: null,
      aRoomRentalDay: null,
      aRoomRentalHalfDay: null,
      aDelegateTotal: null,
      aTotalOffer: null,
      lunchInHalfDayDDR: false,
      breakfastInRoomRate: false,
      isAirConditioning: false,
      isDone: false,
      isApicall: false,
      fileModal: null,
      errorFile: null,
      uploadFile: null,
      uploadSupFile: null,
      roomCount: 0,
      supDocs: [],
      uploadingSupDoc: null,
      staticErrorMessages: {
        file: {
          fileError: "Your file is too big! Please select a file under 2MB",
        },
      },
      expand: false,
      issues: [
        {
          id: 1,
          name: 'Reasons:',
          children: []
        }],
    };
  },
  watch: {
    venue: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(this.venue)
        if (this.venue.submitBy !== undefined && this.venue.submitBy.length > 1 &&
          this.venue.aProposal !== undefined && this.venue.aProposal.length > 0) {
          this.isDone = true;
        }
        // if (val.aAVCosts) {
        this.aAVCosts = val.aAVCosts;
        this.aCateringCosts = val.aCateringCosts;
        this.aRoomRate = val.aRoomRate;
        this.aTotalOffer = val.aTotalOffer;
        this.aAvEquipemnts = val.aAvEquipemnts;
        this.aCatering = val.aCatering;
        this.ddrRate = val.ddrRate;
        this.pkgPrice = val.pkgPrice;
        this.aHalfDayDDR = val.aHalfDayDDR;
        this.aRoomRentalDay = val.aRoomRentalDay;
        this.aRoomRentalHalfDay = val.aRoomRentalHalfDay;
        this.aDelegateTotal = val.aDelegateTotal;
        if (this.inCharge === false) {
          this.show = true;
        } else if (this.venue.proposalStatus === 'submitted') {
          this.show = true;
        } else {
          this.show = false;
        }
        if (this.venue.venueStatus !== 'Confirmed' && this.venue.declineReasons) {
          for (let k = 0; k < this.venue.declineReasons.length; k++) {
            if (this.venue.declineReasons[k] !== 'Other') {
              this.issues[0].children.push(
                {
                  id: k + 2, name: this.venue.declineReasons[k]
                }
              );
            }
          }
          if (this.venue.feedback && this.venue.feedback.length > 0) {
            this.issues[0].children.push(
              {
                id: this.issues[0].children.length + 1, name: this.venue.feedback
              }
            );
          }
          let action = this.venue.venueStatus === 'Cancelled' ? 'cancel' : 'decline';
          this.issues[0].name = this.issues[0].children.length > 1 ? 'Reasons to ' + action : 'Reason to ' + action;
        }
        // }
      },
    },
    inCharge: {
      immediate: true,
      deep: true,
      handler() {
        if (this.inCharge === false) {
          this.show = true;
        } else if (this.venue.proposalStatus === 'submitted') {
          this.show = true;
        } else {
          this.show = false;
        }
      },
    },
    uploadFile() {
      this.errorFile = null;
    }
  },
  computed: {
    computedAddress() {
      let address = this.venue.address;
      let str = "";
      if (address.street) {
        str = str + address.street + ", ";
      }
      if (address.postalCode) {
        str = `${str} [${address.postalCode}], `;
      }

      if (address.city) {
        str = str + address.city + ", ";
      }

      if (address.country) {
        str = str + address.country;
      }
      // {{`${singleRFP.organiserProfile.address.street} [${singleRFP.organiserProfile.address.postalCode}], ${singleRFP.organiserProfile.address.city}, ${singleRFP.organiserProfile.address.country
      return str;
    },
    computedVenueFeatures() {
      let venueList = this.venue;
      let tmp = [];
      if (venueList.ceilHeight) {
        tmp.push({
          name: "Ceiling Height",
          icon: "arrows-alt-v",
          val: this.ForDecimal(venueList.ceilHeight) + " m",
        });
      }
      if (venueList.distance2CityCentre) {
        tmp.push({
          name: "Distance to City Centre",
          icon: "city",
          val: this.ForNumber(venueList.distance2CityCentre) + " km",
        });
      }
      if (venueList.distance2MainStation) {
        tmp.push({
          name: "Distance to Main Station",
          icon: "train",
          val: this.ForNumber(venueList.distance2MainStation) + " km",
        });
      }
      if (venueList.noOfMeetLunch) {
        tmp.push({
          name: "People Capacity (Lunch/Dinner)",
          icon: "utensils",
          val: this.ForNumber(venueList.noOfMeetLunch),
        });
      }
      if (venueList.noOfMeetReception) {
        tmp.push({
          name: "People Capacity (Reception/Cocktail)",
          icon: "cocktail",
          val: this.ForNumber(venueList.noOfMeetReception),
        });
      }
      if (venueList.noOfMeetRooms) {
        tmp.push({
          name: "Total No. of Meeting Rooms",
          icon: "users",
          val: this.ForNumber(venueList.noOfMeetRooms),
        });
      }
      if (venueList.isHybridSolutions) {
        tmp.push({
          name: "Hybrid Event Solutions",
          icon: "laptop",
          val: "Yes",
        });
      }
      if (venueList.isHighSpeedInternet && venueList.bandwidthSpeed) {
        tmp.push({
          name: "High Speed Internet",
          icon: "router",
          val: venueList.bandwidthSpeed
            ? `${venueList.bandwidthSpeed} Mbps`
            : "",
        });
      } else {
        tmp.push({
          name: "High Speed Internet",
          icon: "router",
          val: "No",
        });
      }
      if (venueList.isDedicatedLine) {
        tmp.push({
          name: "Dedicated Line (No Sharing Internet)",
          icon: "network-wired",
          val: "Yes",
        });
      } else {
        tmp.push({
          name: "Dedicated Line (No Sharing Internet)",
          icon: "network-wired",
          val: "No",
        });
      }

      return tmp;
    },
    computedVenueLists() {
      let venueList = this.venue;
      let tmp = [];

      if (venueList.is24HReception) {
        tmp.push({
          name: "24hr Reception",
          icon: "clock",
          val: venueList.is24HReception ? "Yes" : "no",
        });
      }
      if (venueList.isAccomodationAircondition) {
        tmp.push({
          name: "Air Conditioned Rooms",
          icon: "wind",
          val: venueList.isAccomodationAircondition ? "Yes" : "no",
        });
      }
      if (venueList.isDaylightAllRms) {
        tmp.push({
          name: "Daylight in Rooms",
          icon: "sun",
          val: venueList.isDaylightAllRms ? "Yes" : "no",
        });
      }
      if (venueList.isFacilitiesAircondition) {
        tmp.push({
          name: "Air Conditioner",
          icon: "wind",
          val: venueList.isFacilitiesAircondition ? "Yes" : "no",
        });
      }
      if (venueList.isFreeWifi) {
        tmp.push({
          name: "Wi-Fi",
          icon: "wifi",
          val: venueList.isFreeWifi ? "Yes" : "no",
        });
      }
      if (venueList.isGym) {
        tmp.push({
          name: "Gyms",
          icon: "dumbbell",
          val: venueList.isGym ? "Yes" : "no",
        });
      }
      if (venueList.isHospitalityDeskAvailable) {
        tmp.push({
          name: "Ceiling Height",
          icon: "users",
          val: venueList.isHospitalityDeskAvailable ? "Yes" : "no",
        });
      }
      if (venueList.isInsideSwimmingpool) {
        tmp.push({
          name: "Inside Swimming Pools",
          icon: "swimming-pool",
          val: venueList.isInsideSwimmingpool ? "Yes" : "no",
        });
      }
      if (venueList.isOnSiteFacilityTeam) {
        tmp.push({
          name: "On-Site Team",
          icon: "users",
          val: venueList.isOnSiteFacilityTeam ? "Yes" : "no",
        });
      }
      if (venueList.isOutsideSwimmingpool) {
        tmp.push({
          name: "Outside Swimming Pools",
          icon: "swimming-pool",
          val: venueList.isOutsideSwimmingpool ? "Yes" : "no",
        });
      }
      if (venueList.isParkOnsite) {
        tmp.push({
          name: "Parking",
          icon: "parking",
          val: venueList.isParkOnsite ? "Yes" : "no",
        });
      }
      if (venueList.isSustainabilityCertificate) {
        tmp.push({
          name: "Sustainability Certificate",
          icon: "leaf",
          val: venueList.isSustainabilityCertificate ? "Yes" : "no",
        });
      }
      if (venueList.isVideoConference) {
        tmp.push({
          name: "Video Conference",
          icon: "video",
          val: venueList.isVideoConference ? "Yes" : "no",
        });
      }
      if (venueList.isWheelchairAccesible) {
        tmp.push({
          name: "Wheelchair",
          icon: "wheelchair",
          val: venueList.isWheelchairAccesible ? "Yes" : "no",
        });
      }
      return tmp;
    },
    computedVenueAVList() {
      let venueList = this.venue;
      let tmp = [];

      if (venueList.numOfCameras) {
        tmp.push({
          name: "Multiple Cameras",
          icon: "camera-movie",
          val: venueList.numOfCameras ? venueList.numOfCameras : "No",
        });
      }
      if (venueList.isAudioOperator) {
        tmp.push({
          name: "Audio Operator",
          icon: "head-side-headphones",
          val: venueList.isAudioOperator ? "Yes" : "No",
        });
      }
      if (venueList.isOnsiteTechnical) {
        tmp.push({
          name: "Onsite Technical Support",
          icon: "question-circle",
          val: venueList.isOnsiteTechnical ? "Yes" : "No",
        });
      }
      if (venueList.isQualityMicrophones) {
        tmp.push({
          name: venueList.isWirelessMicrophones
            ? "HQ Microphones (Wireless)"
            : "HQ Microphones",
          icon: "microphone-stand",
          val: venueList.isQualityMicrophones
            ? venueList.numOfMicrophonesQuantity
            : "No",
        });
      }

      return tmp;
    },
    getRoomCount() {
      let count = 0;
      for (let i = 0; i < this.venue.noOfHotelRooms.length; i++) {
        count += this.venue.noOfHotelRooms[i].count;
      }
      return count;
    },
  },
  methods: {
    removePendingSupDoc(doc) {
      this.supDocs.splice(this.supDocs.indexOf(doc), 1);
    },
    fileChangedSupDoc(file) {
      let size = file ? file[0].size / 1024 / 1024 : 0;
      if (size > 7) {
        let payload = {
          snackBarText: file[0].name + ' is too large,\nMaximum file size is 2mb.',
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
      } else {
        if (file) {
          this.supDocs.push(file);
          this.errorFile = null;
        }
      }
    },
    fileChanged(file) {
      let size = file ? file.size / 1024 / 1024 : 0;
      if (size > 7) {
        this.errorFile = this.staticErrorMessages.file.fileError;

        // let payload = {
        //   snackBarText: this.staticErrorMessages.file.fileError,
        //   snackBarColor: "error",
        // };
        // this.$store.dispatch("notify/setSnackbarMessage", payload);
        // this.file = null;
      } else {
        // console.log(file);
        if (file) {
          this.file = file;
          this.errorFile = null;
        } else {
          this.file = false;
        }
      }
    },
    capitalize(name) {
      let res, firstChar, tail;
      name = name.trim().toLowerCase();
      res = name.split(" ");
      for (var i = 0; i < res.length; i++) {
        firstChar = res[i].substring(0, 1);
        firstChar = firstChar.toUpperCase();
        tail = tail = res[i].substring(1);
        res[i] = firstChar + tail;
        if (i == 0) {
          name = res[i];
        } else {
          name = name + " " + res[i];
        }
      }
      return name;
    },
    removeFn() {
      if (!this.$route.meta.isDisable) {
        if (!this.venue.selected) {
          let payload = {
            id: this.venue._id,
            rfpId: this.$route.params.id,
            cityShortListId: this.cityId,
          };
          rfpApicalls.removeVenue(payload).then((res) => {
            // console.log(res);
            this.isDone = true;
            let payload2 = {
              snackBarText: `The venue, ${this.capitalize(
                this.venue.venueName
              )}, is successfully removed from the shortlisted city`,
              snackBarColor: "secondary",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.$store.dispatch("rfp/setSingleRFP", res.data);
          });
        } else {
          let payload2 = {
            snackBarText: `The venue, ${this.capitalize(
              this.venue.venueName
            )}, cannot be removed from the RFP (since it has been shortlisted)`,
            snackBarColor: "warning",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }
      }
    },
    forPrice(val) {
      let str = `${this.ForDecimal(val)}`;
      return str;
    },
    selectedFn() {
      let payload = {
        id: this.venue._id,
        selected: !this.venue.selected,
        rfpId: this.$route.params.id,
      };
      rfpApicalls.setSelected(payload).then((res) => {
        let payload2 = {
          snackBarText: !this.venue.selected
            ? `The venue, ${this.capitalize(
              this.venue.venueName
            )}, is shortlisted successfully`
            : `${this.capitalize(
              this.venue.venueName
            )}, is successfully removed from shortlist venues`,
          snackBarColor: !this.venue.selected ? "success" : "secondary",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
        this.$store.dispatch("rfp/setSingleRFP", res.data);
      });
    },
    async uploadSupDoc() {
      for (let i = 0; i < this.supDocs.length; i++) {
        this.uploadingSupDoc = this.supDocs[i];
        this.isApicall = true;
        let payload = {
          _id: this.$route.params.id,
          venueId: this.venue._id,
        };
        var formData = new FormData();
        formData.append("file", this.supDocs[i][0]);
        formData.set("detail", JSON.stringify(payload));
        await rfpApicalls.uploadSupDoc(formData).then((res) => {
          this.$store.dispatch("rfp/setSingleRFP", res.data);
          let payload2 = {
            snackBarText: "Support Document " + (i + 1) + " successfully uploaded!",
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.supDocs.splice(this.supDocs.indexOf(this.supDocs[i]), 1);
        });
      }
    },
    async uploadProposal() {
      let size = this.uploadFile ? this.uploadFile.size / 1024 / 1024 : 0;
      if (size > 7) {
        let payload = {
          snackBarText: this.staticErrorMessages.file.fileError,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        return;
      }
      await this.uploadSupDoc();
      if (this.file) {
        this.isApicall = true;
        let payload = {
          _id: this.$route.params.id,
          venueId: this.venue._id,
        };
        var formData = new FormData();
        formData.append("file", this.file);
        formData.set("detail", JSON.stringify(payload));
        await rfpApicalls.uploadFile(formData).then((res) => {
          console.log(res)
          this.isApicall = false;
          this.dialog = false;
          let payload2 = {
            snackBarText: "Proposal uploaded successfully",
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.$store.dispatch("rfp/setSingleRFP", res.data);
        });
        if (this.venue.submitBy !== undefined && this.venue.submitBy.length > 1 &&
          this.venue.aProposal !== undefined && this.venue.aProposal.length > 0) {
          this.isDone = true;
          console.log('if true')
        }
      }
    },
    removeSupportDoc(deleteFile) {
      alert(deleteFile)
      let payload = {
        _id: this.$route.params.id,
        venueId: this.venue._id,
        doc: deleteFile
      };
      var formData = new FormData();
      formData.set("detail", JSON.stringify(payload));
      rfpApicalls.removeSupportDoc(formData).then((res) => {
        let payload2 = {
          snackBarText: "Supported documents updated!",
          snackBarColor: "success",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
        this.$store.dispatch("rfp/setSingleRFP", res.data);
      });
    },
    adminAddFn(by) {
      let payload = {
        _id: this.$route.params.id,
        venueId: this.venue._id,
        aAVCosts: this.aAVCosts,
        ddrRate: this.ddrRate,
        pkgPrice: this.pkgPrice,
        aCateringCosts: this.aCateringCosts,
        aRoomRate: this.aRoomRate,
        aTotalOffer: this.aTotalOffer,
        aAvEquipemnts: this.venue.aAvEquipemnts,
        aHalfDayDDR: this.aHalfDayDDR,
        aRoomRentalDay: this.aRoomRentalDay,
        aRoomRentalHalfDay: this.aRoomRentalHalfDay,
        aDelegateTotal: this.aDelegateTotal,
        lunchInHalfDayDDR: this.venue.lunchInHalfDayDDR,
        noOfMeetRooms: this.venue.noOfMeetRooms,
        totalNoOfRooms: this.getRoomCount,
        noOfBreakoutRooms: this.venue.noOfBreakoutRooms,
        distance2MainStation: this.venue.distance2MainStation,
        distance2CityCentre: this.venue.distance2CityCentre,
        exhibitSpace: this.venue.exhibitSpace,
        isSustainabilityCertificate: this.venue.isSustainabilityCertificate,
        isFreeWifi: this.venue.isFreeWifi,
        isWheelchairAccesible: this.venue.isWheelchairAccesible,
        isGym: this.venue.isGym,
        parkOnsitePrice: this.venue.parkOnsitePrice,
        isParkOnsite: this.venue.isParkOnsite,
        isAirConditioning: this.venue.isAirConditioning,
        isOutsideSwimmingpool: this.venue.isOutsideSwimmingpool,
        successFee: this.venue.successFee ? this.venue.successFee : false,
        commission: this.venue.commission ? this.venue.commission : false,
        availability: this.venue.availability ? this.venue.availability : false,
        defaultText: this.venue.defaultText ? this.venue.defaultText : false,
        submitBy: by,
        breakfastInRoomRate: this.venue.breakfastInRoomRate
      };
      console.log(payload)
      rfpApicalls.setAdminVal(payload).then((res) => {
        let payload2 = {
          snackBarText: "Venue details updated successfully",
          snackBarColor: "success",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
        this.$store.dispatch("rfp/setSingleRFP", res.data);
      });
      // .catch(err => {
      //   console.log(err);
      // });
      this.isDone = by === 'ADMIN';
    },
    showEditProposal() {
      if (this.inCharge) {
        this.$store.dispatch("notify/setSnackbarMessage", {
          snackBarText: "Sorry!\nCity is in charge",
          snackBarColor: "error",
        });
        return
      }
      this.dialog2 = true;
      console.log(this.venue);
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}

/* selected venue CSS */
.selected-venue-card {
  position: relative;
  border-radius: 5px;
  min-width: 250px;
}

.selected-venue-card .selected-venue-element {
  position: relative;
  border-radius: 5px;
}

.selected-venue-element .selected-venue-placeholder {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.venue-image {
  width: 100%;
  height: 100px;
  border-radius: 5px 5px 0 0;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.venue-title {
  position: absolute;
  top: 45px;
  left: 5px;
  width: 90%;
  font-size: 1rem !important;
  line-height: 100%;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.venue-partner-badge {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.reject-venue,
.selected-venue-element
.selected-venue-placeholder.selected-venue
.accept-venue {
  position: absolute;
  top: 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 22px;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.reject-venue {
  right: 5px;
  cursor: pointer;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.accept-venue {
  left: 5px;
}

.venue-feature-half-column {
  min-height: 36px;
}

.venue-feature-row {
  width: 100%;
  min-height: 36px;
}

.venue-feature-row .feature-icon {
  width: 24px !important;
  height: 24px !important;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.venue-feature-row .feature-explainer-text {
  width: 100%;
  font-family: "TT Hoves" !important;
}

.venue-feature-row .feature-value-text {
  font-family: "TT Hoves Medium" !important;
}

.venue-proposals-row {
  width: 100%;
  min-height: 50px;
}

.venue-proposals-row .proposals-icon {
  width: 32px;
  height: 32px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}

.venue-proposals-row .proposals-explainer-text {
  width: 100%;
  font-family: "TT Hoves" !important;
}

.venue-offer-price {
  background-color: var(--v-platinum-base);
}

.user-profile-btn {
  position: absolute;
  bottom: 0;
}

.city-feature-row {
  max-width: 250px;
  width: 100%;
  height: 36px;
  max-height: 36px;
}

.selected-venue-element
.selected-venue-placeholder.selected-venue
.venue-image
>>> .v-image__image {
  border-radius: 5px 5px 0 0;
}
.hr-divider {
  display: none;
}
</style>
