<template>
  <v-col cols="12" class="px-0">
    <v-row
      class="d-flex flex-row flex-wrap"
      v-if="singleRFP.cityMatch.cityExclude && singleRFP.cityMatch.cityExclude.length && singleRFP.cityMatch.cityExcludeGoogleId"
    >
      <v-chip
        class="ma-2 grey--text"
        label
        :key="index"
        v-for="(city, index) in singleRFP.cityMatch.cityExclude"
      >{{city.formattedAddress}}</v-chip>
    </v-row>
    <v-row class="d-flex flex-row mx-0" v-else>
      <span class="text-left subtitle-1 platinum--text text--darken-3">No countries/cities excluded</span>
    </v-row>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CitiesExclude",
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP"
    })
  }
};
</script>