<template>
  <div>
    <v-row
      class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"
      v-on="on"
      :style="options.list && options.list.length>0?'margin-bottom: 10px':''"
    >
      <!-- the icon of the feature row  -->
      <v-col cols="2" class="d-flex flex-column align-center justify-center px-0 py-1">
        <!-- the feature row icon, the icon class can be populated from array -->
        <font-awesome-icon class="feature-icon pa-1" :icon="options.icon" color="white" size="xs" />
      </v-col>
      <!-- the feature row text -->
      <v-col cols="12" class="d-flex flex-column align-start justify-center pl-2 py-1">
        <h5 class="feature-explainer-text subtitle-2 text-left text-truncate">{{ options.name }}</h5>
      </v-col>
    </v-row>
    <v-row
      class="city-feature-row flex-grow-0 d-flex flex-row flex-nowrap align-center justify-between mx-0 py-0"
      v-on="on" :key="index" v-for="(sub, index) in options.list" style="padding-left: 35px"
    >
      <!-- the icon of the feature row  -->
      <v-col cols="2" class="d-flex flex-column align-center justify-center px-0 py-1">
        <!-- the feature row icon, the icon class can be populated from array -->
        <font-awesome-icon class="feature-icon-sub pa-1" :icon="sub.icon" color="white" size="x-small" />
      </v-col>
      <!-- the feature row text -->
      <v-col cols="12" class="d-flex flex-column align-start justify-center pl-2 py-1">
        <h5 class="feature-explainer-text subtitle-2 text-left text-truncate">{{ sub.name }}</h5>
      </v-col>
    </v-row>
   </div>
</template>

<script>
export default {
  props: ["options"],
};
</script>

<style scoped>
.city-feature-row {
  max-width: 250px;
  width: 100%;
  height: 36px;
  max-height: 36px;
}
.city-feature-row .feature-icon {
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}
.city-feature-row .feature-icon-sub {
  width: 18px;
  height: 18px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}
.city-feature-row .feature-explainer-text {
  width: 100%;
  font-family: "TTHoves" !important;
}
.city-feature-row .feature-value-text {
  font-family: "TTHoves-Medium" !important;
}
.city-feature-row .feature-value-icon {
  width: 24px;
  height: 24px;
}
.city-shortlist-container {
  position: relative;
  /* to offset the city details and image card */
  top: -220px;
  width: 100%;
  /* background-color: #777; */
  overflow-x: auto;
  /* firefox fix */
  scrollbar-width: thin;
}
.city-shortlist-container .shortlist-city-placeholder {
  height: 160px;
  width: 160px;
  border: 1px dotted var(--v-bedrock-base);
  border-radius: 5px;
  background-color: #ffffff;
}
.city-shortlist-container .shortlist-city-placeholder.shortlisted-city {
  position: relative;
  border: none;
}
.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-title {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 70%;
  font-size: 1rem !important;
  line-height: 100%;
}
.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-partner-badge {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}
.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-country-flag {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.city-shortlist-container .shortlist-city-placeholder.shortlisted-city .remove-city {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
/* Large devices (large tablet to laptop) */
@media (min-width: 960px) and (max-width: 1264px) {
  .city-shortlist-container {
    /* reduce the offset to match the size changes */
    top: -180px;
  }
  .city-shortlist-container .shortlist-city-placeholder {
    height: 120px;
    width: 120px;
  }
}
/* Medium devices (large tablet and below) */
@media (max-width: 960px) {
  .city-shortlist-container {
    /* reduce the offset to match the size changes */
    top: -150px;
  }
  .city-shortlist-container .shortlist-city-placeholder {
    height: 90px;
    width: 90px;
  }
  .city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-title {
    font-size: 0.75rem !important;
  }
  .city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-partner-badge {
    width: 18px;
    height: 18px;
  }
  .city-shortlist-container .shortlist-city-placeholder.shortlisted-city .city-country-flag {
    width: 18px;
    height: 18px;
  }
}
</style>
